<template>
  <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <form v-if="addNewRoom" @submit.prevent="createRoom">
      <input v-model="addRoomUsername" type="text" placeholder="Add username"/>
      <button type="submit" :disabled="disableForm || !addRoomUsername">
        Create Room
      </button>
      <button class="button-cancel" @click="addNewRoom = false">Cancel</button>
    </form>

    <form v-if="inviteRoomId" @submit.prevent="addRoomUser">
      <input v-model="invitedUsername" type="text" placeholder="Add username"/>
      <button type="submit" :disabled="disableForm || !invitedUsername">
        Add User
      </button>
      <button class="button-cancel" @click="inviteRoomId = null">Cancel</button>
    </form>

    <form v-if="removeRoomId" @submit.prevent="deleteRoomUser">
      <select v-model="removeUserId">
        <option default value="">Select User</option>
        <option v-for="user in removeUsers" :key="user._id" :value="user._id">
          {{ user.username }}
        </option>
      </select>
      <button type="submit" :disabled="disableForm || !removeUserId">
        Remove User
      </button>
      <button class="button-cancel" @click="removeRoomId = null">Cancel</button>
    </form>
    <!--消息选择-->
    <!--    :message-selection-actions="JSON.stringify(messageSelectionActions)"-->
    <!--    @message-selection-action-handler="-->
    <!--    messageSelectionActionHandler($event.detail[0])-->
    <!--    "-->

    <vue-advanced-chat
        ref="chatWindow"
        :height="screenHeight"
        :theme="theme"
        :styles="JSON.stringify(styles)"
        :current-user-id="currentUserId"
        :room-id="roomId"
        :rooms="JSON.stringify(rooms)"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        :room-message="roomMessage"
        :room-actions="JSON.stringify(roomActions)"
        :menu-actions="JSON.stringify(menuActions)"
        :templates-text="JSON.stringify(templatesText)"
        :auto-scroll="JSON.stringify(autoScroll)"
        :text-messages="JSON.stringify(textMessages)"
        :message-actions="JSON.stringify(messageActions)"
        @fetch-more-rooms="fetchMoreRooms"
        @fetch-messages="fetchMessages($event.detail[0])"
        @send-message="sendMessage($event.detail[0])"
        @edit-message="editMessage($event.detail[0])"
        @delete-message="deleteMessage($event.detail[0])"
        @open-file="openFile($event.detail[0])"
        @open-user-tag="openUserTag($event.detail[0])"
        @add-room="addRoom($event.detail[0])"
        @typing-message="typingMessage($event.detail[0])"
        @room-action-handler="menuActionHandler($event.detail[0])"
        @menu-action-handler="menuActionHandler($event.detail[0])"
        @send-message-reaction="sendMessageReaction($event.detail[0])"
        @toggle-rooms-list="$emit('show-demo-options', $event.detail[0].opened)"
    >
      <!-- <div
        v-for="message in messages"
        :slot="'message_' + message._id"
        :key="message._id"
      >
        New message container
      </div> -->
    </vue-advanced-chat>
  </div>
</template>

<script>
import {parseTimestamp, formatTimestamp} from './utils/dates'
import {generateRandomFileName, getBlobFromUrl} from './utils/files'
import io from 'socket.io-client';
import {post} from '@/api'
// import logoAvatar from './assets/logo.png'

import {register} from 'vue-advanced-chat'

register()

export default {
  props: {
    theme: {type: String, required: true},
    isDevice: {type: Boolean, required: true}
  },

  emits: ['show-demo-options'],

  data() {
    return {
      token: localStorage.getItem('token'),
      socket: null,
      currentUserId: null,
      currentUserAvatar: null,
      roomsPerPage: 15,
      rooms: [],
      roomId: '',
      lastLoadedRoomOffset: 0,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      roomMessage: '',
      lastLoadedMessageOffset: 0,
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      roomActions: [
        {name: 'inviteUser', title: 'Invite User'},
        {name: 'removeUser', title: 'Remove User'},
        {name: 'deleteRoom', title: 'Delete Room'}
      ],
      menuActions: [
        {name: 'inviteUser', title: 'Invite User'},
        {name: 'removeUser', title: 'Remove User'},
        {name: 'deleteRoom', title: 'Delete Room'}
      ],
      // messageSelectionActions: [{name: 'deleteMessages', title: 'Delete'}],
      styles: {container: {borderRadius: '4px'}},
      templatesText: [
        {
          tag: 'help',
          text: 'This is the help'
        },
        {
          tag: 'action',
          text: 'This is the action'
        },
        {
          tag: 'action 2',
          text: 'This is the second action'
        }
      ],
      autoScroll: {
        send: {
          new: true,
          newAfterScrollUp: true
        },
        receive: {
          new: true,
          newAfterScrollUp: false
        }
      },
      textMessages: {
        ROOMS_EMPTY: '暂无对话',
        ROOM_EMPTY: '未选择对话',
        NEW_MESSAGES: '新消息',
        MESSAGE_DELETED: '此消息已被删除',
        MESSAGES_EMPTY: '暂无消息',
        CONVERSATION_STARTED: '对话开始于：',
        TYPE_MESSAGE: '请输入您的消息',
        SEARCH: '搜索',
        IS_ONLINE: '在线',
        LAST_SEEN: '最后在线',
        IS_TYPING: '正在输入...',
        CANCEL_SELECT_MESSAGE: '取消选择'
      },
      messageActions: [
        {
          name: 'replyMessage',
          title: 'Reply'
        },
        {
          name: 'editMessage',
          title: 'Edit Message',
          onlyMe: true
        },
        {
          name: 'deleteMessage',
          title: 'Delete Message',
          onlyMe: true
        },
      ]
    }
  },

  computed: {
    screenHeight() {
      return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 80px)'
    }
  },

  beforeDestroy() {
    // 在组件销毁时关闭WebSocket连接
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  },

  mounted() {
    // this.addCss()

    // 配置ws
    this.initWebSocket()
  },

  methods: {
    initWebSocket() {
      this.socket = io(process.env.VUE_APP_WS_URL, {
        query: {token: this.token},
      });

      this.socket.on('connect', () => {
        this.$message.success('聊天室连接成功！');
        this.fetchRooms()
      })
      this.socket.on('disconnect', () => {
        this.$message.info('聊天室断开连接！');
      })
      this.socket.on('connect_error', () => {
        this.$message.error('聊天室连接失败！');
      })
      this.socket.on('message', (data) => {
        this.handleWebSocketMessage(data)
      })
    },

    handleWebSocketMessage(message) {
      const handlers = {
        'user_id': this.handleUserIdMessage,
        'rooms': this.handleRoomsMessage,
        'messages': this.handleMessagesMessage,
        'newMessage': this.handleNewMessage,
        'updateRoomTypingUsers': this.handleUpdateRoomTypingUsers,
        'warning': this.handleWarningMessage,
        'joinRoom': this.handleJoinRoomMessage,
        'deleteMessage': this.handleDeleteMessage,
        'editMessage': this.handleEditMessage,
        'updateReactions': this.handleUpdateReactions,
        'userStatusChange': this.handleUserStatusChange,
        'markMessageSeen': this.handleMarkMessageSeen
      };

      const handler = handlers[message.type];
      if (handler) {
        handler.call(this, message.data);
      }
    },

    handleUserIdMessage(data) {
      this.currentUserId = data.userId.toString();
      this.currentUserAvatar = data.avatar.toString();
    },

    handleRoomsMessage(data) {
      if (data.length === 0 || data.length < this.roomsPerPage) {
        this.roomsLoaded = true;
      }
      this.rooms = [...this.rooms, ...data];
      this.lastLoadedRoomOffset = data.length + this.lastLoadedRoomOffset;
      this.loadingRooms = false;
      this.handleRoomsUnreadAndSeenNew()
    },

    handleMessagesMessage(data) {
      if (this.selectedRoom && this.selectedRoom.roomId) {
        if (data.length === 0 || data.length < this.messagesPerPage) {
          this.messagesLoaded = true;
        }
        this.messages = [...data, ...this.messages];
        this.lastLoadedMessageOffset = data.length + this.lastLoadedMessageOffset;
        const selectedRoomIndex = this.rooms.findIndex(room => room.roomId === this.selectedRoom.roomId)
        if (this.rooms[selectedRoomIndex].unreadCount !== 0) {
          // 延迟2s执行
          setTimeout(() => {
            this.rooms[selectedRoomIndex].unreadCount = 0;
          }, 1000);
          this.updateUnreadCount(this.rooms[selectedRoomIndex].roomId, 0)
        }
        this.rooms = [...this.rooms]
        this.messages.forEach(message => {
          message.seen = this.checkMessageSeen(message)
          if (!message.seen && message.senderId !== this.currentUserId) {
            this.markMessagesSeen(message);
          }
        })
      }
    },

    handleNewMessage(data) {
      const newMessage = this.formatMessage(data);
      const userInRoom = this.selectedRoom.roomId === newMessage.roomId
      if (userInRoom) {
        this.messages = [...this.messages, newMessage];
        this.markMessagesSeen(newMessage);
      } else {
        newMessage.seen = false;
        newMessage.new = true;
      }
      const roomIndex = this.rooms.findIndex(r => r.roomId === newMessage.roomId);
      if (roomIndex !== -1) {
        this.rooms[roomIndex].lastMessage = newMessage;
        this.rooms[roomIndex].index = data.timestamp;
        if (newMessage.senderId !== this.currentUserId && !userInRoom) {
          this.rooms[roomIndex].unreadCount += 1
        }
        this.rooms = [...this.rooms];
      }
      this.updateRoomIndex(roomIndex)
    },

    updateUnreadCount(roomId, unreadCount) {
      this.socket.emit('updateUnreadCount', {
        roomId: roomId,
        unreadCount: unreadCount,
        userId: this.currentUserId
      })
    },

    checkMessageSeen(message) {
      if (typeof message.seen === 'object' && message.seen !== null) {
        if (this.currentUserId === message.senderId) {
          return true
        } else {
          return this.currentUserId in message.seen
        }
      }
      return false
    },

    handleUpdateRoomTypingUsers(data) {
      const {roomId, userId, action} = data;
      const roomIndex = this.rooms.findIndex(room => room.roomId === roomId);
      if (action === 'add') {
        if (!this.rooms[roomIndex].typingUsers.includes(userId)) {
          this.rooms[roomIndex].typingUsers = [
            ...this.rooms[roomIndex].typingUsers,
            userId
          ];
        }
      } else if (action === 'remove') {
        this.rooms[roomIndex].typingUsers =
            this.rooms[roomIndex].typingUsers.filter(id => id !== userId);
      }
      this.rooms = [...this.rooms]
    },

    handleWarningMessage(data) {
      this.$message.warning(data);
    },

    handleJoinRoomMessage(data) {
      this.loadingRooms = true;
      this.rooms = [data, ...this.rooms];
      this.loadingRooms = false;
      this.handleRoomsUnreadAndSeenNew()
    },

    handleRoomsUnreadAndSeenNew() {
      this.rooms.forEach(room => {
        if (Object.keys(room.unreadCount).length !== 0) {
          room.unreadCount = room.unreadCount[this.currentUserId]
          if (room.lastMessage) {
            room.lastMessage.seen = this.checkMessageSeen(room.lastMessage)
            room.lastMessage.new = !room.lastMessage.seen
          }
        } else {
          room.unreadCount = 0
          room.lastMessage.seen = null
          room.lastMessage.new = null
        }
      })
    },

    handleDeleteMessage(data) {
      const messageId = data.messageId;
      if (this.selectedRoom.roomId === data.roomId) {
        const messageIndex = this.messages.findIndex(message => message._id === messageId);
        if (messageIndex !== -1) {
          this.messages[messageIndex].deleted = true;
          this.messages = [...this.messages];
        }
      }
    },

    handleEditMessage(data) {
      const editedMessage = data;
      if (this.selectedRoom.roomId === editedMessage.roomId) {
        const editMsgIndex = this.messages.findIndex(message => message._id === editedMessage.messageId);
        if (editMsgIndex !== -1) {
          this.messages[editMsgIndex].edited = editedMessage.edited;
          this.messages[editMsgIndex].content = editedMessage.content;
          this.messages = [...this.messages];
        }
      }
    },

    handleUpdateReactions(data) {
      const updatedReactions = data;
      if (this.selectedRoom.roomId === updatedReactions.roomId) {
        const updatedMsgIndex = this.messages.findIndex(message => message._id === updatedReactions.messageId);
        if (updatedMsgIndex !== -1) {
          this.messages[updatedMsgIndex].reactions = updatedReactions.reactions;
          this.messages = [...this.messages];
        }
      }
    },

    handleUserStatusChange(data) {
      const roomId = data.roomId;
      const userId = data.userId;
      const status = data.status;
      const targetRoom = this.rooms.find(room => room.roomId == roomId);
      if (targetRoom) {
        const targetUser = targetRoom.users.find(user => user._id == userId);
        if (targetUser) {
          targetUser.status = status;
        }
      }
      this.rooms = [...this.rooms];
    },

    handleMarkMessageSeen(data) {
      if (this.selectedRoom.roomId === data.roomId) {
        const updatedMsgIndex = this.messages.findIndex(message => message._id === data.messageId);
        if (updatedMsgIndex !== -1) {
          this.messages[updatedMsgIndex].seen = true;
          this.messages = [...this.messages];
        }
      }

      const roomIndex = this.rooms.findIndex(room => room.roomId === data.roomId && room.lastMessage._id === data.messageId)
      if (roomIndex !== -1) {
        this.rooms[roomIndex].lastMessage.seen = true
        this.rooms[roomIndex].lastMessage.new = false
        this.rooms = [...this.rooms]
      }
    },

    // async addCss() {
    //   if (import.meta.env.MODE === 'development') {
    //     const styles = await import('./../../src/styles/index.scss')
    //     const style = document.createElement('style')
    //     style.innerHTML = styles.default
    //     this.$refs.chatWindow.shadowRoot.appendChild(style)
    //   }
    // },

    resetRooms() {
      this.loadingRooms = true
      this.rooms = []
      this.lastLoadedRoomOffset = 0
      this.roomsLoaded = false
      this.resetMessages()
    },

    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.lastLoadedMessageOffset = 0
    },

    fetchRooms() {
      this.resetRooms()
      this.fetchMoreRooms()
    },

    async fetchMoreRooms() {
      // 通过WebSocket发送获取更多房间的请求
      this.socket.emit('fetchMoreRooms', {
        currentUserId: this.currentUserId,
        roomsPerPage: this.roomsPerPage,
        lastLoadedRoomOffset: this.lastLoadedRoomOffset
      })
    },

    formatLastMessage(message, room) {
      if (!message.timestamp) return
      let content = message.content
      if (message.files?.length) {
        const file = message.files[0]
        content = `${file.name}.${file.extension || file.type}`
      }
      const username =
          message.sender_id !== this.currentUserId
              ? room.users.find(user => message.sender_id === user._id)?.username
              : ''
      return {
        ...message,
        ...{
          _id: message.id,
          content,
          senderId: message.sender_id,
          timestamp: formatTimestamp(
              new Date(message.timestamp.seconds * 1000),
              message.timestamp
          ),
          username: username,
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
              message.sender_id !== this.currentUserId &&
              (!message.seen || !message.seen[this.currentUserId])
        }
      }
    },

    fetchMessages({room, options = {}}) {
      this.$emit('show-demo-options', false)
      if (options.reset) {
        this.resetMessages()
      }
      this.selectedRoom = room
      this.socket.emit('fetchMessages', {
        roomId: room.roomId,
        currentUserId: this.currentUserId,
        messagesPerPage: this.messagesPerPage,
        lastLoadedMessageOffset: this.lastLoadedMessageOffset
      })
    },

    markMessagesSeen(message) {
      if (
          message.sender_id !== this.currentUserId &&
          (!message.seen || !message.seen[this.currentUserId])
      ) {
        // 通过WebSocket发送标记消息已读的请求
        this.socket.emit('markMessageSeen', {
          roomId: message.roomId,
          messageId: message._id,
          userId: this.currentUserId
        })
      }
    },

    formatMessage(message) {
      const formattedMessage = {
        ...message,
        ...{
          senderId: message.sender_id,
          _id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, 'HH:mm'),
          date: parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
          distributed: true
        }
      }
      if (message.seen && message.seen[this.currentUserId]) {
        message.seen = true
      }
      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.sender_id
          }
        }
      }
      return formattedMessage
    },

    async sendMessage({content, roomId, files, replyMessage}) {
      const message = {
        sender_id: this.currentUserId,
        avatar: this.currentUserAvatar,
        content
      }
      if (files) {
        message.files = this.formattedFiles(files)
      }
      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        }
        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files
        }
      }
      // 通过WebSocket发送发送消息的请求
      this.socket.emit('sendMessage', {
        message,
        roomId
      })

      // 上传文件
      if (files) {
        await this.uploadFiles(files)
      }
    },

    updateRoomIndex(roomIndex) {
      if (roomIndex > -1) {
        // 从原位置移除当前房间
        const currentRoom = this.rooms.splice(roomIndex, 1)[0];
        // 将当前房间插入到列表的第一个位置
        this.rooms.unshift(currentRoom);
      }
    },

    async editMessage({messageId, newContent, roomId, files}) {
      const editedMessage = {
        content: newContent,
        roomId: roomId,
        messageId: messageId
      }
      if (files) {
        editedMessage.files = this.formattedFiles(files)
      } else {
        editedMessage.files = null
      }
      // 通过WebSocket发送编辑消息的请求
      this.socket.emit('editMessage', {editedMessage})

      // 上传文件
      if (files) {
        await this.uploadFiles(files)
      }
    },

    async deleteMessage({message, roomId}) {
      // 通过WebSocket发送删除消息的请求
      this.socket.emit('deleteMessage', {
        roomId,
        messageId: message._id
      })
    },

    async uploadFiles(files) {
      // 创建 FormData 对象
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const blob = await getBlobFromUrl(files[i].url || files[i].localUrl)
        const fileAfter = new File([blob], files[i].name, {type: files[i].type});
        formData.append(`files[${i}]`, fileAfter);
      }

      // 添加额外的参数
      formData.append('rename', 'false');
      formData.append('module_type', 'chat');

      // 使用 axios 发送 POST 请求
      post('/file/upload', formData, "multipart/form-data");
    },

    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find(message => message._id === messageId)
      if (!message || !message.files) return
      message.files.find(file => file.url === fileUrl).progress = progress
      this.messages = [...this.messages]
    },

    formattedFiles(files) {
      const formattedFiles = []
      files.forEach(file => {
        const fileName = generateRandomFileName()
        file.name = fileName + '.' + file.extension
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension,
          url: file.url || file.localUrl
        }
        if (file.audio) {
          messageFile.audio = true
          messageFile.duration = file.duration
        }
        formattedFiles.push(messageFile)
      })
      return formattedFiles
    },

    openFile({file}) {
      window.open(file.file.url, '_blank')
    },

    async openUserTag({user}) {
      let roomId
      this.rooms.forEach(room => {
        if (room.users.length === 2) {
          const userId1 = room.users[0]._id
          const userId2 = room.users[1]._id
          if (
              (userId1 === user._id || userId1 === this.currentUserId) &&
              (userId2 === user._id || userId2 === this.currentUserId)
          ) {
            roomId = room.roomId
          }
        }
      })
      if (roomId) {
        this.roomId = roomId
      }
    },

    async loadRoom(query) {
      query.forEach(async room => {
        if (this.loadingRooms) return
        // 通过WebSocket发送更新房间的请求
        this.socket.emit('updateRoom', {
          roomId: room.id,
          lastUpdated: new Date()
        })
        this.roomId = room.id
        this.fetchRooms()
      })
    },

    menuActionHandler({action, roomId}) {
      switch (action.name) {
        case 'inviteUser':
          return this.inviteUser(roomId)
        case 'removeUser':
          return this.removeUser(roomId)
        case 'deleteRoom':
          return this.deleteRoom(roomId)
      }
    },

    messageSelectionActionHandler({action, messages, roomId}) {
      switch (action.name) {
        case 'deleteMessages':
          messages.forEach(message => {
            this.deleteMessage({message, roomId})
          })
      }
    },

    async sendMessageReaction({reaction, remove, messageId, roomId}) {
      // 通过WebSocket发送消息反应的请求
      this.socket.emit('sendMessageReaction', {
        reaction,
        remove,
        messageId,
        roomId,
        userId: this.currentUserId
      })
    },

    typingMessage({message, roomId}) {
      if (roomId) {
        if (message?.length > 1) {
          this.typingMessageCache = message
          return
        }
        if (message?.length === 1 && this.typingMessageCache) {
          this.typingMessageCache = message
          return
        }
        this.typingMessageCache = message
        // 通过WebSocket发送用户正在输入的请求
        this.socket.emit('updateRoomTypingUsers', {
          roomId,
          userId: this.currentUserId,
          action: message ? 'add' : 'remove'
        })
      }
    },

    addRoom() {
      this.resetForms()
      this.addNewRoom = true
    },

    async createRoom() {
      this.disableForm = true
      // 通过WebSocket发送创建房间的请求
      this.socket.emit('createRoom', {
        username: this.addRoomUsername,
        currentUserId: this.currentUserId
      })
      this.addNewRoom = false
      this.addRoomUsername = ''
    },

    inviteUser(roomId) {
      this.resetForms()
      this.inviteRoomId = roomId
    },

    async addRoomUser() {
      this.disableForm = true
      // 通过WebSocket发送邀请用户的请求
      this.socket.emit('addRoomUser', {
        roomId: this.inviteRoomId,
        username: this.invitedUsername,
        currentUserId: this.currentUserId
      })
      this.inviteRoomId = null
      this.invitedUsername = ''
    },

    removeUser(roomId) {
      this.resetForms()
      this.removeRoomId = roomId
      this.removeUsers = this.rooms.find(room => room.roomId === roomId).users
    },

    async deleteRoomUser() {
      this.disableForm = true
      // 通过WebSocket发送移除用户的请求
      this.socket.emit('deleteRoomUser', {
        roomId: this.removeRoomId,
        userId: this.removeUserId
      })
      this.removeRoomId = null
      this.removeUserId = ''
    },

    async deleteRoom(roomId) {
      // 通过WebSocket发送删除房间的请求
      this.socket.emit('deleteRoom', {
        roomId
      });
    },

    resetForms() {
      this.disableForm = false
      this.addNewRoom = null
      this.addRoomUsername = ''
      this.inviteRoomId = null
      this.invitedUsername = ''
      this.removeRoomId = null
      this.removeUserId = ''
    },

    disconnectWebSocket() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    }
  }
}
</script>

<!--<style lang="scss" scoped>-->
<style scoped>
.window-container {
  width: 100%;
}

/*.window-mobile {*/

/*  form {*/
/*    padding: 0 10px 10px;*/
/*  }*/

/*}*/

form {
  padding-bottom: 20px;
}

/*input {*/
/*  padding: 5px;*/
/*  width: 140px;*/
/*  height: 21px;*/
/*  border-radius: 4px;*/
/*  border: 1px solid #d2d6da;*/
/*  outline: none;*/
/*  font-size: 14px;*/
/*  vertical-align: middle;*/

/*  &*/
/*  ::placeholder {*/
/*    color: #9ca6af;*/
/*  }*/

/*}*/

/*button {*/
/*  background: #1976d2;*/
/*  color: #fff;*/
/*  outline: none;*/
/*  cursor: pointer;*/
/*  border-radius: 4px;*/
/*  padding: 8px 12px;*/
/*  margin-left: 10px;*/
/*  border: none;*/
/*  font-size: 14px;*/
/*  transition: 0.3s;*/
/*  vertical-align: middle;*/

/*  &*/
/*  :hover {*/
/*    opacity: 0.8;*/
/*  }*/

/*  &*/
/*  :active {*/
/*    opacity: 0.6;*/
/*  }*/

/*  &*/
/*  :disabled {*/
/*    cursor: initial;*/
/*    background: #c6c9cc;*/
/*    opacity: 0.6;*/
/*  }*/

/*}*/

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}

select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>